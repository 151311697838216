import * as React from "react"
import { useEffect } from "react"
import ReactGA from "react-ga"

// Components
import SEO from "../components/seo"
import WrapButton from "../components/Buttons/wrapButton"
import { Section, Container } from "../components/StyledComponents/layoutComponentStyles"

// Animations
import { motion } from "framer-motion"
import { easeInOutExpo } from "../animations/animUtils"
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Interfaces
interface NotFoundPageProps {}

// SEO
const seo = {
  title: "404",
  description: "It looks like this page doesn't exist... whoops!",
}

/*









*/

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = () => {
  // Effects
  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
    }, 600)

    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      <Section fullHeight>
        <motion.div
          initial='initial'
          animate='animate'
          exit={{
            height: "0%",
            transition: { duration: 0.9, ease: easeInOutExpo },
          }}
          className='u-page-reveal-container'
        >
          <div className='fof-container'>
            <Container fourteenClm>
              <h1 className='o-type-xxl'>
                <span className='o-outline-type'>404</span>
              </h1>
              <h5 style={{ textAlign: "center" }}>Whoops, looks like you took a wrong turn somewhere...</h5>
              <WrapButton link='/' text='Go home' centered={true} />
            </Container>
          </div>
        </motion.div>
      </Section>
    </>
  )
}

export default NotFoundPage
